import React from 'react'
// import { StaticImage } from 'gatsby-plugin-image'
import { GrTools } from 'react-icons/gr'
const Hero = ({ showIcon }) => {
  return (
    <header className="hero">
      {showIcon && (
        <GrTools
          placeholder="blurred"
          className="hero-person"
          alt="Icon ToolBox"
          color="var(--clr-grey-9)"
        />
      )}
    </header>
  )
}

export default Hero
