import React from 'react'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Posts from '../components/Posts'
import Mission from '../components/Mission'
import Seo from '../components/SEO/PageSeo'

import { graphql } from 'gatsby'
const IndexPage = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data

  return (
    <Layout>
      <Seo />
      <Hero /*showIcon */ />
      <Mission />
      <Posts posts={posts} title="dernière mise à jour" />
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { language: { eq: "fr" } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          category
          slug
          date(formatString: "Do. MMMM YYYY", locale:"fr")
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        timeToRead
        excerpt
      }
    }
  }
`

export default IndexPage
