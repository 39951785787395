import React from 'react'
import styled from 'styled-components'

const Mission = () => {
  return (
    <Wrapper>
      <h1>
        On construit une maison avec Maisons de la Côte Atlantique (MCA) en
        France
      </h1>
      <p>On habite en Allemagne. Voici comment cela se déroule.</p>
      <p class="faint">
        On partage nos expériences quant à la construction, la recherche du
        crédit et du terrain, etc
      </p>
      <div className="underline"></div>
    </Wrapper>
  )
}

export default Mission

const Wrapper = styled.article`
  text-align: center;
  margin: 2rem 0;
  h1 {
    font-size: 1.5rem;
  }
  .faint {
    margin-right: auto;
    margin-left:auto;
    color: #ccc;
    font-size: 0.8em;
    font-style: italic;
    max-width: 60%;
    text-align:center;
  }
`
