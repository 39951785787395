import React from 'react'
import {Link} from "gatsby"
import { GiGrass, GiFinishLine, GiStoneAxe } from 'react-icons/gi'

const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <Link to="/status#planning">
          <GiGrass className="social-icon not-current-icon" alt="Planning (completé)"></GiGrass>
        </Link>
      </li>
      <li>
      <Link to="/status#construction">
          <GiStoneAxe className="social-icon current-icon" alt="Construction (actif)"></GiStoneAxe>
        </Link>
      </li>
      <li>
      <Link to="/status#finished">
          <GiFinishLine className="social-icon not-current-icon" alt="Construction finis et aprés (todo)"></GiFinishLine>
        </Link>
      </li>
    </ul>
  )
}
export default SocialLinks
