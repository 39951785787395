import React from 'react'
import StatusIcons from '../../constants/statusIcons'
// import { StaticImage } from 'gatsby-plugin-image'
import Title from './Title'
import styled from 'styled-components'
import { GiLinkedRings } from 'react-icons/gi'

const About = () => {
  return (
    <Wrapper>
      <Title title="nous" />
      <GiLinkedRings className="img" size="3em" />
      {/*<StaticImage
        src="../../assets/banner-about.jpeg"
        layout="fixed"
        width={100}
        height={100}
        alt="author"
        className="img"
      />*/}
      <p>On construit une maison avec MCA en France - on vive en Allemagne.</p>
      <StatusIcons styleClass="banner-icons" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  p {
    color: var(--clr-grey-5);
  }
  .img {
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
`
export default About
